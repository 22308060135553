@import "./validate.css";
.pricing-feature__inner {
  display: flex;
  width: 100%;
  justify-content: left;
  align-items: center;
}

.pricing-feature.item-included {
  display: flex;
  padding-left: 20%;
  align-items: center;
}
span.pricing-feature__text.up {
  padding-top: 20px;
}
.no-padding {
  padding-left: 10% !important;
}
.h-440  {
  height: 440px;
}