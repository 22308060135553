@import './styles/index.css';
.elementor-widget-image {
  text-align: center;
}
.elementor-widget-image a {
  display: inline-block;
}
.elementor-widget-image a img[src$=".svg"] {
  width: 48px;
}
.elementor-widget-image img {
  vertical-align: middle;
  display: inline-block;
}
/*! elementor - v3.5.4 - 23-01-2022 */
.elementor-widget-text-editor.elementor-drop-cap-view-stacked
  .elementor-drop-cap {
  background-color: #818a91;
  color: #fff;
}
.elementor-widget-text-editor.elementor-drop-cap-view-framed
  .elementor-drop-cap {
  color: #818a91;
  border: 3px solid;
  background-color: transparent;
}
.elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
  .elementor-drop-cap {
  margin-top: 8px;
}
.elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
  .elementor-drop-cap-letter {
  width: 1em;
  height: 1em;
}
.elementor-widget-text-editor .elementor-drop-cap {
  float: left;
  text-align: center;
  line-height: 1;
  font-size: 50px;
}
.elementor-widget-text-editor .elementor-drop-cap-letter {
  display: inline-block;
}
/*! elementor - v3.5.4 - 23-01-2022 */
.elementor-heading-title {
  padding: 0;
  margin: 0;
  line-height: 1;
}
.elementor-widget-heading
  .elementor-heading-title[class*="elementor-size-"]
  > a {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}
.elementor-widget-heading .elementor-heading-title.elementor-size-small {
  font-size: 15px;
}
.elementor-widget-heading .elementor-heading-title.elementor-size-medium {
  font-size: 19px;
}
.elementor-widget-heading .elementor-heading-title.elementor-size-large {
  font-size: 29px;
}
.elementor-widget-heading .elementor-heading-title.elementor-size-xl {
  font-size: 39px;
}
.elementor-widget-heading .elementor-heading-title.elementor-size-xxl {
  font-size: 59px;
}
/*! elementor - v3.5.4 - 23-01-2022 */
.elementor-counter .elementor-counter-number-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 69px;
  font-weight: 600;
  line-height: 1;
}
.elementor-counter .elementor-counter-number-prefix,
.elementor-counter .elementor-counter-number-suffix {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  white-space: pre-wrap;
}
.elementor-counter .elementor-counter-number-prefix {
  text-align: right;
}
.elementor-counter .elementor-counter-number-suffix {
  text-align: left;
}
.elementor-counter .elementor-counter-title {
  text-align: center;
  font-size: 19px;
  font-weight: 400;
  line-height: 2.5;
}
/*! elementor-pro - v3.5.2 - 28-11-2021 */
.elementor-button.elementor-hidden,
.elementor-hidden {
  display: none;
}
.e-form__step {
  width: 100%;
}
.e-form__step:not(.elementor-hidden) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.e-form__buttons {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.e-form__buttons,
.e-form__buttons__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.e-form__indicators {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  font-size: 13px;
  margin-bottom: var(--e-form-steps-indicators-spacing);
}
.e-form__indicators,
.e-form__indicators__indicator {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.e-form__indicators__indicator {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  padding: 0 var(--e-form-steps-divider-gap);
}
.e-form__indicators__indicator__progress {
  width: 100%;
  position: relative;
  background-color: var(--e-form-steps-indicator-progress-background-color);
  border-radius: var(--e-form-steps-indicator-progress-border-radius);
  overflow: hidden;
}
.e-form__indicators__indicator__progress__meter {
  width: var(--e-form-steps-indicator-progress-meter-width, 0);
  height: var(--e-form-steps-indicator-progress-height);
  line-height: var(--e-form-steps-indicator-progress-height);
  padding-right: 15px;
  border-radius: var(--e-form-steps-indicator-progress-border-radius);
  background-color: var(--e-form-steps-indicator-progress-color);
  color: var(--e-form-steps-indicator-progress-meter-color);
  text-align: right;
  -webkit-transition: width 0.1s linear;
  -o-transition: width 0.1s linear;
  transition: width 0.1s linear;
}
.e-form__indicators__indicator:first-child {
  padding-left: 0;
}
.e-form__indicators__indicator:last-child {
  padding-right: 0;
}
.e-form__indicators__indicator--state-inactive {
  color: var(--e-form-steps-indicator-inactive-primary-color, #c2cbd2);
}
.e-form__indicators__indicator--state-inactive
  [class*="indicator--shape-"]:not(.e-form__indicators__indicator--shape-none) {
  background-color: var(
    --e-form-steps-indicator-inactive-secondary-color,
    #fff
  );
}
.e-form__indicators__indicator--state-inactive object,
.e-form__indicators__indicator--state-inactive svg {
  fill: var(--e-form-steps-indicator-inactive-primary-color, #c2cbd2);
}
.e-form__indicators__indicator--state-active {
  color: var(--e-form-steps-indicator-active-primary-color, #39b54a);
  border-color: var(--e-form-steps-indicator-active-secondary-color, #fff);
}
.e-form__indicators__indicator--state-active
  [class*="indicator--shape-"]:not(.e-form__indicators__indicator--shape-none) {
  background-color: var(--e-form-steps-indicator-active-secondary-color, #fff);
}
.e-form__indicators__indicator--state-active object,
.e-form__indicators__indicator--state-active svg {
  fill: var(--e-form-steps-indicator-active-primary-color, #39b54a);
}
.e-form__indicators__indicator--state-completed {
  color: var(--e-form-steps-indicator-completed-secondary-color, #fff);
}
.e-form__indicators__indicator--state-completed
  [class*="indicator--shape-"]:not(.e-form__indicators__indicator--shape-none) {
  background-color: var(
    --e-form-steps-indicator-completed-primary-color,
    #39b54a
  );
}
.e-form__indicators__indicator--state-completed
  .e-form__indicators__indicator__label {
  color: var(--e-form-steps-indicator-completed-primary-color, #39b54a);
}
.e-form__indicators__indicator--state-completed
  .e-form__indicators__indicator--shape-none {
  color: var(--e-form-steps-indicator-completed-primary-color, #39b54a);
  background-color: initial;
}
.e-form__indicators__indicator--state-completed object,
.e-form__indicators__indicator--state-completed svg {
  fill: var(--e-form-steps-indicator-completed-secondary-color, #fff);
}
.e-form__indicators__indicator__icon {
  width: var(--e-form-steps-indicator-padding, 30px);
  height: var(--e-form-steps-indicator-padding, 30px);
  font-size: var(--e-form-steps-indicator-icon-size);
  border-width: 1px;
  border-style: solid;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 10px;
}
.e-form__indicators__indicator__icon img,
.e-form__indicators__indicator__icon object,
.e-form__indicators__indicator__icon svg {
  width: var(--e-form-steps-indicator-icon-size);
  height: auto;
}
.e-form__indicators__indicator__icon .e-font-icon-svg {
  height: 1em;
}
.e-form__indicators__indicator__number {
  width: var(--e-form-steps-indicator-padding, 30px);
  height: var(--e-form-steps-indicator-padding, 30px);
  border-width: 1px;
  border-style: solid;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 10px;
}
.e-form__indicators__indicator--shape-circle {
  border-radius: 50%;
}
.e-form__indicators__indicator--shape-square {
  border-radius: 0;
}
.e-form__indicators__indicator--shape-rounded {
  border-radius: 5px;
}
.e-form__indicators__indicator--shape-none {
  border: 0;
}
.e-form__indicators__indicator__label {
  text-align: center;
}
.e-form__indicators__indicator__separator {
  width: 100%;
  height: var(--e-form-steps-divider-width);
  background-color: #c2cbd2;
}
.e-form__indicators--type-icon,
.e-form__indicators--type-icon_text,
.e-form__indicators--type-number,
.e-form__indicators--type-number_text {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.e-form__indicators--type-icon .e-form__indicators__indicator__separator,
.e-form__indicators--type-icon_text .e-form__indicators__indicator__separator,
.e-form__indicators--type-number .e-form__indicators__indicator__separator,
.e-form__indicators--type-number_text
  .e-form__indicators__indicator__separator {
  margin-top: calc(
    var(--e-form-steps-indicator-padding, 30px) / 2 -
      var(--e-form-steps-divider-width, 1px) / 2
  );
}
.elementor-field-type-hidden {
  display: none;
}
.elementor-field-type-html {
  display: inline-block;
}
.elementor-login .elementor-lost-password,
.elementor-login .elementor-remember-me {
  font-size: 0.85em;
}
.elementor-field-type-recaptcha_v3 .elementor-field-label {
  display: none;
}
.elementor-field-type-recaptcha_v3 .grecaptcha-badge {
  z-index: 1;
}
.elementor-button .elementor-form-spinner {
  -webkit-box-ordinal-group: 4;
  -ms-flex-order: 3;
  order: 3;
}
.elementor-form .elementor-button > span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.elementor-form .elementor-button .elementor-button-text {
  white-space: normal;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
}
.elementor-form .elementor-button svg {
  height: auto;
}
.elementor-form .elementor-button .e-font-icon-svg {
  height: 1em;
}
 /*! elementor - v3.5.4 - 23-01-2022 */
 .elementor-widget.elementor-icon-list--layout-inline
 .elementor-widget-container {
 overflow: hidden;
}
.elementor-widget
 .elementor-icon-list-items.elementor-inline-items {
 margin-right: -8px;
 margin-left: -8px;
}
.elementor-widget
 .elementor-icon-list-items.elementor-inline-items
 .elementor-icon-list-item {
 margin-right: 8px;
 margin-left: 8px;
}
.elementor-widget
 .elementor-icon-list-items.elementor-inline-items
 .elementor-icon-list-item:after {
 width: auto;
 left: auto;
 right: auto;
 position: relative;
 height: 100%;
 border-top: 0;
 border-bottom: 0;
 border-right: 0;
 border-left-width: 1px;
 border-style: solid;
 right: -8px;
}
.elementor-widget .elementor-icon-list-items {
 list-style-type: none;
 margin: 0;
 padding: 0;
}
.elementor-widget .elementor-icon-list-item {
 margin: 0;
 padding: 0;
 position: relative;
}
.elementor-widget .elementor-icon-list-item:after {
 position: absolute;
 bottom: 0;
 width: 100%;
}
.elementor-widget .elementor-icon-list-item,
.elementor-widget .elementor-icon-list-item a {
 display: -webkit-box;
 display: -ms-flexbox;
 display: flex;
 -webkit-box-align: center;
 -ms-flex-align: center;
 align-items: center;
 font-size: inherit;
}
.elementor-widget
 .elementor-icon-list-icon
 + .elementor-icon-list-text {
 -ms-flex-item-align: center;
 align-self: center;
 padding-left: 5px;
}
.elementor-widget .elementor-icon-list-icon {
 display: -webkit-box;
 display: -ms-flexbox;
 display: flex;
}
.elementor-widget .elementor-icon-list-icon svg {
 width: var(--e-icon-list-icon-size, 1em);
 height: var(--e-icon-list-icon-size, 1em);
}
.elementor-widget .elementor-icon-list-icon i {
 width: 1.25em;
 font-size: var(--e-icon-list-icon-size);
}
.elementor-widget.elementor-widget-icon-list
 .elementor-icon-list-icon {
 text-align: var(--e-icon-list-icon-align);
}
.elementor-widget.elementor-widget-icon-list
 .elementor-icon-list-icon
 svg {
 margin: var(
   --e-icon-list-icon-margin,
   0 calc(var(--e-icon-list-icon-size, 1em) * 0.25) 0
     0
 );
}
.elementor-widget.elementor-list-item-link-full_width
 a {
 width: 100%;
}
.elementor-widget.elementor-align-center
 .elementor-icon-list-item,
.elementor-widget.elementor-align-center
 .elementor-icon-list-item
 a {
 -webkit-box-pack: center;
 -ms-flex-pack: center;
 justify-content: center;
}
.elementor-widget.elementor-align-center
 .elementor-icon-list-item:after {
 margin: auto;
}
.elementor-widget.elementor-align-center
 .elementor-inline-items {
 -webkit-box-pack: center;
 -ms-flex-pack: center;
 justify-content: center;
}
.elementor-widget.elementor-align-left
 .elementor-icon-list-item,
.elementor-widget.elementor-align-left
 .elementor-icon-list-item
 a {
 -webkit-box-pack: start;
 -ms-flex-pack: start;
 justify-content: flex-start;
 text-align: left;
}
.elementor-widget.elementor-align-left
 .elementor-inline-items {
 -webkit-box-pack: start;
 -ms-flex-pack: start;
 justify-content: flex-start;
}
.elementor-widget.elementor-align-right
 .elementor-icon-list-item,
.elementor-widget.elementor-align-right
 .elementor-icon-list-item
 a {
 -webkit-box-pack: end;
 -ms-flex-pack: end;
 justify-content: flex-end;
 text-align: right;
}
.elementor-widget.elementor-align-right
 .elementor-icon-list-items {
 -webkit-box-pack: end;
 -ms-flex-pack: end;
 justify-content: flex-end;
}
.elementor-widget:not(.elementor-align-right)
 .elementor-icon-list-item:after {
 left: 0;
}
.elementor-widget:not(.elementor-align-left)
 .elementor-icon-list-item:after {
 right: 0;
}
@media (max-width: 1024px) {
 .elementor-widget.elementor-tablet-align-center
   .elementor-icon-list-item,
 .elementor-widget.elementor-tablet-align-center
   .elementor-icon-list-item
   a,
 .elementor-widget.elementor-tablet-align-center
   .elementor-icon-list-items {
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center;
 }
 .elementor-widget.elementor-tablet-align-center
   .elementor-icon-list-item:after {
   margin: auto;
 }
 .elementor-widget.elementor-tablet-align-left
   .elementor-icon-list-items {
   -webkit-box-pack: start;
   -ms-flex-pack: start;
   justify-content: flex-start;
 }
 .elementor-widget.elementor-tablet-align-left
   .elementor-icon-list-item,
 .elementor-widget.elementor-tablet-align-left
   .elementor-icon-list-item
   a {
   -webkit-box-pack: start;
   -ms-flex-pack: start;
   justify-content: flex-start;
   text-align: left;
 }
 .elementor-widget.elementor-tablet-align-right
   .elementor-icon-list-items {
   -webkit-box-pack: end;
   -ms-flex-pack: end;
   justify-content: flex-end;
 }
 .elementor-widget.elementor-tablet-align-right
   .elementor-icon-list-item,
 .elementor-widget.elementor-tablet-align-right
   .elementor-icon-list-item
   a {
   -webkit-box-pack: end;
   -ms-flex-pack: end;
   justify-content: flex-end;
   text-align: right;
 }
 .elementor-widget:not(.elementor-tablet-align-right)
   .elementor-icon-list-item:after {
   left: 0;
 }
 .elementor-widget:not(.elementor-tablet-align-left)
   .elementor-icon-list-item:after {
   right: 0;
 }
}
@media (max-width: 767px) {
 .elementor-widget.elementor-mobile-align-center
   .elementor-icon-list-item,
 .elementor-widget.elementor-mobile-align-center
   .elementor-icon-list-item
   a,
 .elementor-widget.elementor-mobile-align-center
   .elementor-icon-list-items {
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   justify-content: center;
 }
 .elementor-widget.elementor-mobile-align-center
   .elementor-icon-list-item:after {
   margin: auto;
 }
 .elementor-widget.elementor-mobile-align-left
   .elementor-icon-list-items {
   -webkit-box-pack: start;
   -ms-flex-pack: start;
   justify-content: flex-start;
 }
 .elementor-widget.elementor-mobile-align-left
   .elementor-icon-list-item,
 .elementor-widget.elementor-mobile-align-left
   .elementor-icon-list-item
   a {
   -webkit-box-pack: start;
   -ms-flex-pack: start;
   justify-content: flex-start;
   text-align: left;
 }
 .elementor-widget.elementor-mobile-align-right
   .elementor-icon-list-items {
   -webkit-box-pack: end;
   -ms-flex-pack: end;
   justify-content: flex-end;
 }
 .elementor-widget.elementor-mobile-align-right
   .elementor-icon-list-item,
 .elementor-widget.elementor-mobile-align-right
   .elementor-icon-list-item
   a {
   -webkit-box-pack: end;
   -ms-flex-pack: end;
   justify-content: flex-end;
   text-align: right;
 }
 .elementor-widget:not(.elementor-mobile-align-right)
   .elementor-icon-list-item:after {
   left: 0;
 }
 .elementor-widget:not(.elementor-mobile-align-left)
   .elementor-icon-list-item:after {
   right: 0;
 }
}   /*! elementor - v3.5.4 - 23-01-2022 */
.elementor-widget-text-editor.elementor-drop-cap-view-stacked
  .elementor-drop-cap {
  background-color: #818a91;
  color: #fff;
}
.elementor-widget-text-editor.elementor-drop-cap-view-framed
  .elementor-drop-cap {
  color: #818a91;
  border: 3px solid;
  background-color: transparent;
}
.elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
  .elementor-drop-cap {
  margin-top: 8px;
}
.elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
  .elementor-drop-cap-letter {
  width: 1em;
  height: 1em;
}
.elementor-widget-text-editor .elementor-drop-cap {
  float: left;
  text-align: center;
  line-height: 1;
  font-size: 50px;
}
.elementor-widget-text-editor
  .elementor-drop-cap-letter {
  display: inline-block;
}
 /*! elementor - v3.5.4 - 23-01-2022 */
 .elementor-heading-title {
  padding: 0;
  margin: 0;
  line-height: 1;
}
.elementor-widget-heading
  .elementor-heading-title[class*="elementor-size-"]
  > a {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-small {
  font-size: 15px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-medium {
  font-size: 19px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-large {
  font-size: 29px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-xl {
  font-size: 39px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-xxl {
  font-size: 59px;
}
/*! elementor - v3.5.4 - 23-01-2022 */
.elementor-widget-image-box
.elementor-image-box-content {
width: 100%;
}
@media (min-width: 768px) {
.elementor-widget-image-box.elementor-position-left
  .elementor-image-box-wrapper,
.elementor-widget-image-box.elementor-position-right
  .elementor-image-box-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.elementor-widget-image-box.elementor-position-right
  .elementor-image-box-wrapper {
  text-align: right;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.elementor-widget-image-box.elementor-position-left
  .elementor-image-box-wrapper {
  text-align: left;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.elementor-widget-image-box.elementor-position-top
  .elementor-image-box-img {
  margin: auto;
}
.elementor-widget-image-box.elementor-vertical-align-top
  .elementor-image-box-wrapper {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.elementor-widget-image-box.elementor-vertical-align-middle
  .elementor-image-box-wrapper {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.elementor-widget-image-box.elementor-vertical-align-bottom
  .elementor-image-box-wrapper {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
}
@media (max-width: 767px) {
.elementor-widget-image-box
  .elementor-image-box-img {
  margin-left: auto !important;
  margin-right: auto !important;
  margin-bottom: 15px;
}
}
.elementor-widget-image-box
.elementor-image-box-img {
display: inline-block;
}
.elementor-widget-image-box
.elementor-image-box-title
a {
color: inherit;
}
.elementor-widget-image-box
.elementor-image-box-wrapper {
text-align: center;
}
.elementor-widget-image-box
.elementor-image-box-description {
margin: 0;
}
/*! elementor - v3.5.4 - 23-01-2022 */
.elementor-widget.elementor-icon-list--layout-inline
.elementor-widget-container {
overflow: hidden;
}
.elementor-widget
.elementor-icon-list-items.elementor-inline-items {
margin-right: -8px;
margin-left: -8px;
}
.elementor-widget
.elementor-icon-list-items.elementor-inline-items
.elementor-icon-list-item {
margin-right: 8px;
margin-left: 8px;
}
.elementor-widget
.elementor-icon-list-items.elementor-inline-items
.elementor-icon-list-item:after {
width: auto;
left: auto;
right: auto;
position: relative;
height: 100%;
border-top: 0;
border-bottom: 0;
border-right: 0;
border-left-width: 1px;
border-style: solid;
right: -8px;
}
.elementor-widget .elementor-icon-list-items {
list-style-type: none;
margin: 0;
padding: 0;
}
.elementor-widget .elementor-icon-list-item {
margin: 0;
padding: 0;
position: relative;
}
.elementor-widget .elementor-icon-list-item:after {
position: absolute;
bottom: 0;
width: 100%;
}
.elementor-widget .elementor-icon-list-item,
.elementor-widget .elementor-icon-list-item a {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align: center;
-ms-flex-align: center;
align-items: center;
font-size: inherit;
}
.elementor-widget
.elementor-icon-list-icon
+ .elementor-icon-list-text {
-ms-flex-item-align: center;
align-self: center;
padding-left: 5px;
}
.elementor-widget .elementor-icon-list-icon {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
}
.elementor-widget .elementor-icon-list-icon svg {
width: var(--e-icon-list-icon-size, 1em);
height: var(--e-icon-list-icon-size, 1em);
}
.elementor-widget .elementor-icon-list-icon i {
width: 1.25em;
font-size: var(--e-icon-list-icon-size);
}
.elementor-widget.elementor-widget-icon-list
.elementor-icon-list-icon {
text-align: var(--e-icon-list-icon-align);
}
.elementor-widget.elementor-widget-icon-list
.elementor-icon-list-icon
svg {
margin: var(
  --e-icon-list-icon-margin,
  0 calc(var(--e-icon-list-icon-size, 1em) * 0.25)
    0 0
);
}
.elementor-widget.elementor-list-item-link-full_width
a {
width: 100%;
}
.elementor-widget.elementor-align-center
.elementor-icon-list-item,
.elementor-widget.elementor-align-center
.elementor-icon-list-item
a {
-webkit-box-pack: center;
-ms-flex-pack: center;
justify-content: center;
}
.elementor-widget.elementor-align-center
.elementor-icon-list-item:after {
margin: auto;
}
.elementor-widget.elementor-align-center
.elementor-inline-items {
-webkit-box-pack: center;
-ms-flex-pack: center;
justify-content: center;
}
.elementor-widget.elementor-align-left
.elementor-icon-list-item,
.elementor-widget.elementor-align-left
.elementor-icon-list-item
a {
-webkit-box-pack: start;
-ms-flex-pack: start;
justify-content: flex-start;
text-align: left;
}
.elementor-widget.elementor-align-left
.elementor-inline-items {
-webkit-box-pack: start;
-ms-flex-pack: start;
justify-content: flex-start;
}
.elementor-widget.elementor-align-right
.elementor-icon-list-item,
.elementor-widget.elementor-align-right
.elementor-icon-list-item
a {
-webkit-box-pack: end;
-ms-flex-pack: end;
justify-content: flex-end;
text-align: right;
}
.elementor-widget.elementor-align-right
.elementor-icon-list-items {
-webkit-box-pack: end;
-ms-flex-pack: end;
justify-content: flex-end;
}
.elementor-widget:not(.elementor-align-right)
.elementor-icon-list-item:after {
left: 0;
}
.elementor-widget:not(.elementor-align-left)
.elementor-icon-list-item:after {
right: 0;
}
@media (max-width: 1024px) {
.elementor-widget.elementor-tablet-align-center
  .elementor-icon-list-item,
.elementor-widget.elementor-tablet-align-center
  .elementor-icon-list-item
  a,
.elementor-widget.elementor-tablet-align-center
  .elementor-icon-list-items {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.elementor-widget.elementor-tablet-align-center
  .elementor-icon-list-item:after {
  margin: auto;
}
.elementor-widget.elementor-tablet-align-left
  .elementor-icon-list-items {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.elementor-widget.elementor-tablet-align-left
  .elementor-icon-list-item,
.elementor-widget.elementor-tablet-align-left
  .elementor-icon-list-item
  a {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-align: left;
}
.elementor-widget.elementor-tablet-align-right
  .elementor-icon-list-items {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.elementor-widget.elementor-tablet-align-right
  .elementor-icon-list-item,
.elementor-widget.elementor-tablet-align-right
  .elementor-icon-list-item
  a {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: right;
}
.elementor-widget:not(.elementor-tablet-align-right)
  .elementor-icon-list-item:after {
  left: 0;
}
.elementor-widget:not(.elementor-tablet-align-left)
  .elementor-icon-list-item:after {
  right: 0;
}
}
@media (max-width: 767px) {
.elementor-widget.elementor-mobile-align-center
  .elementor-icon-list-item,
.elementor-widget.elementor-mobile-align-center
  .elementor-icon-list-item
  a,
.elementor-widget.elementor-mobile-align-center
  .elementor-icon-list-items {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.elementor-widget.elementor-mobile-align-center
  .elementor-icon-list-item:after {
  margin: auto;
}
.elementor-widget.elementor-mobile-align-left
  .elementor-icon-list-items {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.elementor-widget.elementor-mobile-align-left
  .elementor-icon-list-item,
.elementor-widget.elementor-mobile-align-left
  .elementor-icon-list-item
  a {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-align: left;
}
.elementor-widget.elementor-mobile-align-right
  .elementor-icon-list-items {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.elementor-widget.elementor-mobile-align-right
  .elementor-icon-list-item,
.elementor-widget.elementor-mobile-align-right
  .elementor-icon-list-item
  a {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: right;
}
.elementor-widget:not(.elementor-mobile-align-right)
  .elementor-icon-list-item:after {
  left: 0;
}
.elementor-widget:not(.elementor-mobile-align-left)
  .elementor-icon-list-item:after {
  right: 0;
}
}
/*! elementor - v3.5.4 - 23-01-2022 */
.elementor-heading-title {
  padding: 0;
  margin: 0;
  line-height: 1;
}
.elementor-widget-heading
  .elementor-heading-title[class*="elementor-size-"]
  > a {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-small {
  font-size: 15px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-medium {
  font-size: 19px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-large {
  font-size: 29px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-xl {
  font-size: 39px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-xxl {
  font-size: 59px;
}
/*! elementor - v3.5.4 - 23-01-2022 */
.elementor-widget-text-editor.elementor-drop-cap-view-stacked
.elementor-drop-cap {
background-color: #818a91;
color: #fff;
}
.elementor-widget-text-editor.elementor-drop-cap-view-framed
.elementor-drop-cap {
color: #818a91;
border: 3px solid;
background-color: transparent;
}
.elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
.elementor-drop-cap {
margin-top: 8px;
}
.elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
.elementor-drop-cap-letter {
width: 1em;
height: 1em;
}
.elementor-widget-text-editor .elementor-drop-cap {
float: left;
text-align: center;
line-height: 1;
font-size: 50px;
}
.elementor-widget-text-editor
.elementor-drop-cap-letter {
display: inline-block;
}
  /*! elementor - v3.5.4 - 23-01-2022 */
  .elementor-counter
  .elementor-counter-number-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 69px;
  font-weight: 600;
  line-height: 1;
}
.elementor-counter
  .elementor-counter-number-prefix,
.elementor-counter
  .elementor-counter-number-suffix {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  white-space: pre-wrap;
}
.elementor-counter
  .elementor-counter-number-prefix {
  text-align: right;
}
.elementor-counter
  .elementor-counter-number-suffix {
  text-align: left;
}
.elementor-counter
  .elementor-counter-title {
  text-align: center;
  font-size: 19px;
  font-weight: 400;
  line-height: 2.5;
}
 /*! elementor - v3.5.4 - 23-01-2022 */
 .elementor-heading-title {
  padding: 0;
  margin: 0;
  line-height: 1;
}
.elementor-widget-heading
  .elementor-heading-title[class*="elementor-size-"]
  > a {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-small {
  font-size: 15px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-medium {
  font-size: 19px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-large {
  font-size: 29px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-xl {
  font-size: 39px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-xxl {
  font-size: 59px;
}
/*! elementor - v3.5.4 - 23-01-2022 */
.elementor-widget-text-editor.elementor-drop-cap-view-stacked
.elementor-drop-cap {
background-color: #818a91;
color: #fff;
}
.elementor-widget-text-editor.elementor-drop-cap-view-framed
.elementor-drop-cap {
color: #818a91;
border: 3px solid;
background-color: transparent;
}
.elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
.elementor-drop-cap {
margin-top: 8px;
}
.elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
.elementor-drop-cap-letter {
width: 1em;
height: 1em;
}
.elementor-widget-text-editor .elementor-drop-cap {
float: left;
text-align: center;
line-height: 1;
font-size: 50px;
}
.elementor-widget-text-editor
.elementor-drop-cap-letter {
display: inline-block;
}
/*! elementor - v3.5.4 - 23-01-2022 */
.elementor-counter
.elementor-counter-number-wrapper {
display: -webkit-box;
display: -ms-flexbox;
display: flex;
font-size: 69px;
font-weight: 600;
line-height: 1;
}
.elementor-counter
.elementor-counter-number-prefix,
.elementor-counter
.elementor-counter-number-suffix {
-webkit-box-flex: 1;
-ms-flex-positive: 1;
flex-grow: 1;
white-space: pre-wrap;
}
.elementor-counter
.elementor-counter-number-prefix {
text-align: right;
}
.elementor-counter
.elementor-counter-number-suffix {
text-align: left;
}
.elementor-counter
.elementor-counter-title {
text-align: center;
font-size: 19px;
font-weight: 400;
line-height: 2.5;
}
/*! elementor - v3.5.4 - 23-01-2022 */
.elementor-heading-title {
  padding: 0;
  margin: 0;
  line-height: 1;
}
.elementor-widget-heading
  .elementor-heading-title[class*="elementor-size-"]
  > a {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-small {
  font-size: 15px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-medium {
  font-size: 19px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-large {
  font-size: 29px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-xl {
  font-size: 39px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-xxl {
  font-size: 59px;
}
 /*! elementor - v3.5.4 - 23-01-2022 */
 .elementor-widget-text-editor.elementor-drop-cap-view-stacked
 .elementor-drop-cap {
 background-color: #818a91;
 color: #fff;
}
.elementor-widget-text-editor.elementor-drop-cap-view-framed
 .elementor-drop-cap {
 color: #818a91;
 border: 3px solid;
 background-color: transparent;
}
.elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
 .elementor-drop-cap {
 margin-top: 8px;
}
.elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
 .elementor-drop-cap-letter {
 width: 1em;
 height: 1em;
}
.elementor-widget-text-editor .elementor-drop-cap {
 float: left;
 text-align: center;
 line-height: 1;
 font-size: 50px;
}
.elementor-widget-text-editor
 .elementor-drop-cap-letter {
 display: inline-block;
}
/*! elementor - v3.5.4 - 23-01-2022 */
.elementor-heading-title {
  padding: 0;
  margin: 0;
  line-height: 1;
}
.elementor-widget-heading
  .elementor-heading-title[class*="elementor-size-"]
  > a {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-small {
  font-size: 15px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-medium {
  font-size: 19px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-large {
  font-size: 29px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-xl {
  font-size: 39px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-xxl {
  font-size: 59px;
}
 /*! elementor - v3.5.4 - 23-01-2022 */
 .elementor-heading-title {
  padding: 0;
  margin: 0;
  line-height: 1;
}
.elementor-widget-heading
  .elementor-heading-title[class*="elementor-size-"]
  > a {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-small {
  font-size: 15px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-medium {
  font-size: 19px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-large {
  font-size: 29px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-xl {
  font-size: 39px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-xxl {
  font-size: 59px;
}
/*! elementor - v3.5.4 - 23-01-2022 */
.elementor-widget-text-editor.elementor-drop-cap-view-stacked
.elementor-drop-cap {
background-color: #818a91;
color: #fff;
}
.elementor-widget-text-editor.elementor-drop-cap-view-framed
.elementor-drop-cap {
color: #818a91;
border: 3px solid;
background-color: transparent;
}
.elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
.elementor-drop-cap {
margin-top: 8px;
}
.elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
.elementor-drop-cap-letter {
width: 1em;
height: 1em;
}
.elementor-widget-text-editor .elementor-drop-cap {
float: left;
text-align: center;
line-height: 1;
font-size: 50px;
}
.elementor-widget-text-editor
.elementor-drop-cap-letter {
display: inline-block;
}
/*! elementor - v3.5.4 - 23-01-2022 */
.elementor-heading-title {
  padding: 0;
  margin: 0;
  line-height: 1;
}
.elementor-widget-heading
  .elementor-heading-title[class*="elementor-size-"]
  > a {
  color: inherit;
  font-size: inherit;
  line-height: inherit;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-small {
  font-size: 15px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-medium {
  font-size: 19px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-large {
  font-size: 29px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-xl {
  font-size: 39px;
}
.elementor-widget-heading
  .elementor-heading-title.elementor-size-xxl {
  font-size: 59px;
}
/*! elementor - v3.5.4 - 23-01-2022 */
.elementor-widget-text-editor.elementor-drop-cap-view-stacked
.elementor-drop-cap {
background-color: #818a91;
color: #fff;
}
.elementor-widget-text-editor.elementor-drop-cap-view-framed
.elementor-drop-cap {
color: #818a91;
border: 3px solid;
background-color: transparent;
}
.elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
.elementor-drop-cap {
margin-top: 8px;
}
.elementor-widget-text-editor:not(.elementor-drop-cap-view-default)
.elementor-drop-cap-letter {
width: 1em;
height: 1em;
}
.elementor-widget-text-editor .elementor-drop-cap {
float: left;
text-align: center;
line-height: 1;
font-size: 50px;
}
.elementor-widget-text-editor
.elementor-drop-cap-letter {
display: inline-block;
}
 /*! elementor - v3.5.4 - 23-01-2022 */
 .elementor-accordion {
  text-align: left;
}
.elementor-accordion .elementor-accordion-item {
  border: 1px solid #d4d4d4;
}
.elementor-accordion
  .elementor-accordion-item
  + .elementor-accordion-item {
  border-top: none;
}
.elementor-accordion .elementor-tab-title {
  margin: 0;
  padding: 15px 20px;
  font-weight: 700;
  line-height: 1;
  cursor: pointer;
  outline: none;
}
.elementor-accordion
  .elementor-tab-title
  .elementor-accordion-icon {
  display: inline-block;
  width: 1.5em;
}
.elementor-accordion
  .elementor-tab-title
  .elementor-accordion-icon
  svg {
  width: 1em;
  height: 1em;
}
.elementor-accordion
  .elementor-tab-title
  .elementor-accordion-icon.elementor-accordion-icon-right {
  float: right;
  text-align: right;
}
.elementor-accordion
  .elementor-tab-title
  .elementor-accordion-icon.elementor-accordion-icon-left {
  float: left;
  text-align: left;
}
.elementor-accordion
  .elementor-tab-title
  .elementor-accordion-icon
  .elementor-accordion-icon-closed {
  display: block;
}
.elementor-accordion
  .elementor-tab-title
  .elementor-accordion-icon
  .elementor-accordion-icon-opened,
.elementor-accordion
  .elementor-tab-title.elementor-active
  .elementor-accordion-icon-closed {
  display: none;
}
.elementor-accordion
  .elementor-tab-title.elementor-active
  .elementor-accordion-icon-opened {
  display: block;
}
.elementor-accordion .elementor-tab-content {
  display: none;
  padding: 15px 20px;
  border-top: 1px solid #d4d4d4;
}
@media (max-width: 767px) {
  .elementor-accordion .elementor-tab-title {
    padding: 12px 15px;
  }
  .elementor-accordion
    .elementor-tab-title
    .elementor-accordion-icon {
    width: 1.2em;
  }
  .elementor-accordion .elementor-tab-content {
    padding: 7px 15px;
  }
}