@font-face {
  font-family: "Lexa";
  src: local("Lexa"), url(fonts/lexa.ttf) format("truetype");
}
.main-container {
  background: #e5e5e5;
}
.page-title {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 30px;
  /* identical to box height */

  /* Brand-main */

  color: #000000;
}
.page-details {
    width: fit-content;
    margin: 10px;
    margin-left: 0px;
}
.form-card {
  font-family: lexa !important;
  width: 80%;
  height: fit-content;
  padding: 10px;
  border-radius: 10px;
}
.search-box {
    background: #FFFFFF;
    width: fit-content;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.policyNumberInput {
  border: none;
  padding: 12px 10px;
  font-family: "Nunito", Sans-serif;
  font-size: 14px;
  font-weight: 400;
  width: 300px;
}
.search-policy {
  background: #059669;
  color: #fff;
  padding: 10px;
  height: 100%;
  outline: none;
}
.user-policy-card {
  background: #ffffff;
  box-shadow: 2px 4px 61px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  height: fit-content;
  padding: 18px;
  display: flex;
  flex-direction: row;
  margin-top: 12px;
}

.card-section {
  width: 33.33%;
}

h4.user-name {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  color: #000000;
}

.card-section.user h6 {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */
  color: #b39314;
  margin-top: 5px;
}

p.tag {
  background: rgba(33, 86, 218, 0.24);
  color: #2156DA;
  border-radius: 25px;
  width: fit-content;
  padding-left: 5px;
  padding-right: 5px;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  margin-top: 5px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
p.tag.active {
  background: #09bc8a;
  color: #FFFFFF;
}
p.amount {
  margin-top: 50px;
  font-weight: 700;
  font-size: 16px;
  line-height: 18px;
  /* identical to box height */
  color: #000000;
}

.details h6 {
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  /* Black-50 */
  color: rgba(0, 0, 0, 0.5);
}

.details h5 {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  color: #000000;
  margin-top: 5px;
  margin-bottom: 10px;
}

.details button {
  background: #09bc8a;
  border-radius: 6px;
  height: 40px;
  /* width: 150px; */
  padding: 9px;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  /* White */
  color: #ffffff;
  font-weight: 600;
}
.total-details {
  font-size: 10px;
  display: block;
}
.select-months {
  width: 50%;
  border: 1px solid #059669;
  border-radius: 5px;
  margin: 5px;
  height: 40px;
}
a.cancel-renewal {
  cursor: pointer;
  margin-left: 20%;
  color: #ff8f8f;
}