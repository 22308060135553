/* Basic styles for your div */
.container {
    width: 100%;
    max-width: 400px;
    margin: 0 auto;
    box-sizing: border-box;
}

/* Mobile phones */
@media (max-width: 480px) {
    .container {
        max-width: 100%;
    }
}

/* Tablets */
@media (min-width: 481px) and (max-width: 768px) {
    .container {
        max-width: 80%;
    }
}

/* Laptops */
@media (min-width: 769px) and (max-width: 1024px) {
    .container {
        max-width: 60%;
    }
}

/* Desktops and larger screens */
@media (min-width: 1025px) {
    .container {
        max-width: 50%;
    }
}